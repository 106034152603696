import ToykoGhoul from "../assets/Tokyo-Ghoul-Wallpaper-For-PC.jpg";
import Image2 from "../assets/1132051.jpg";
import Image3 from "../assets/1132065.jpg";

export const ShopList = [
    {
        name: "Tokyo Ghoul",
        image: ToykoGhoul,
        price: 1
    },
    {
        name: "Image 2",
        image: Image2,
        price: 1,
    },
    {
        name: "Image 3",
        image: Image3,
        price: 1,
    },

    {
        name: "Tokyo Ghoul2",
        image: ToykoGhoul,
        price: 1, 
    }
];